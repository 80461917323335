/* eslint-disable */
import classNames from 'classnames';

import type { ReactElement, MouseEvent } from 'react';

import { Mobile, Desktop } from '@components/Layout';

import Button from '@atoms/Button';

import { useDisablePageScrollWhenShowModal } from '@utils/hooks';

import CloseIcon from '@assets/icon-close.svg';

import styles from './css';

type ButtonProps = React.ComponentProps<typeof Button>;

type Props = {
  show: boolean;
  setShow: (show: boolean) => void;
  headerLabel?: string;
  hideMobileHeaderText?: boolean;
  headerRightCustomComponent?: ReactElement;
  headerLeftButton?: JSX.Element | null;
  centerButtonLabel?: string;
  leftButtonLabel?: string;
  rightButtonLabel?: string;
  onClickCloseButton?: () => void;
  onClickCenterButton?: () => void;
  onClickLeftButton?: () => void;
  onClickRightButton?: () => void;
  children: ReactElement;
  rightButtonDisabled?: boolean;
  showFooter?: boolean;
  position?: 'absolute' | '';
  maxWidth?: string;
  scrollable?: boolean;
  withBackground?: boolean;
  footerContents?: ReactElement | null;
  showHeader?: boolean;
  centerButtonStyle?: ButtonProps['btnStyle'];
};

const Modal = ({
  show,
  setShow,
  headerLabel,
  hideMobileHeaderText = false,
  headerRightCustomComponent,
  headerLeftButton = null,
  centerButtonLabel,
  leftButtonLabel,
  rightButtonLabel,
  onClickCloseButton,
  onClickCenterButton,
  onClickRightButton,
  onClickLeftButton,
  children,
  rightButtonDisabled,
  showFooter = true,
  position = '',
  maxWidth = '520px',
  scrollable = true,
  withBackground = false,
  footerContents = null,
  showHeader = true,
  centerButtonStyle = '',
}: Props): JSX.Element | null => {
  useDisablePageScrollWhenShowModal(show, scrollable);

  const closeModal = (event: MouseEvent) => {
    event.preventDefault();
    setShow(!show);
    onClickCloseButton?.();
  };

  if (!show) return null;
  return (
    <div className={classNames('modal-container', { active: show }, position)}>
      <div className="modal" style={{ maxWidth }}>
        {showHeader && (
          <div className="modal-header">
            <div className="header-left-button">{headerLeftButton}</div>
            <Desktop>
              <h3 className="title">{headerLabel}</h3>
            </Desktop>
            <div className="header-right-cont">
              {headerRightCustomComponent}
              <button
                type="button"
                onClick={closeModal}
                className="close-button"
              >
                <CloseIcon />
              </button>
            </div>
          </div>
        )}

        <div className="modal-contents">
          {!hideMobileHeaderText && (
            <Mobile>
              <h3 className="title">{headerLabel}</h3>
            </Mobile>
          )}
          {children}
        </div>

        {showFooter && (
          <div className="modal-footer">
            {footerContents ? (
              footerContents
            ) : (
              <>
                {leftButtonLabel && (
                  <button
                    className="button-link"
                    type="button"
                    onClick={onClickLeftButton}
                  >
                    {leftButtonLabel}
                  </button>
                )}
                {centerButtonLabel && (
                  <Button
                    btnStyle={centerButtonStyle}
                    customClass="center-button"
                    label={centerButtonLabel}
                    onClick={onClickCenterButton}
                  />
                )}
                {rightButtonLabel && (
                  <Button
                    label={rightButtonLabel}
                    onClick={onClickRightButton}
                    disabled={rightButtonDisabled}
                  />
                )}
              </>
            )}
          </div>
        )}
      </div>

      <div
        className={classNames('background', { show: withBackground })}
        onClick={(e) => closeModal(e)}
        aria-hidden="true"
      />

      <style jsx>{styles}</style>
    </div>
  );
};

export default Modal;
